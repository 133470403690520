const PHONE_NUMBER = '+7 (495) 981-60-70';

export const COMPANY = {
  phoneNumberFormatted: PHONE_NUMBER,
  phoneNumber: PHONE_NUMBER.replaceAll(/[^\d+]/g, ''),
} as const;

export const SEXES_LIST = [
  {
    name: 'Мужской',
    value: 'male',
  },
  {
    name: 'Женский',
    value: 'female',
  },
];

export const BIRTHDAY_RANGE = {
  min: new Date(1900, 0, 1),
  max: () => {
    const now = new Date();
    now.setFullYear(now.getFullYear() - 10);
    return now;
  },
  default: () => {
    const now = new Date();
    now.setFullYear(now.getFullYear() - 30);
    return now;
  },
};
